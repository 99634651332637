import { ref, inject } from "vue";
import { useStore } from "vuex";
import api from "@/services/userApi";

export default function useDomains() {
  const t = inject("t");
  const store = useStore();
  const domain = ref({
    domain: "",
    regexp: "",
    country: "",
  });
  const loading = ref(true);
  const domains = ref([]);
  const notify = inject("notify");
  const n = (label, type = "danger") => {
    notify({
      message: t(`${label}`),
      type,
    });
  };

  //   FIND Domains
  const findDomains = async () => {
    try {
      const response = await api.get("/admin/xpbx/settings/domain");

      if (response?.data) {
        const mappedDomains = response.data.map((item) => {
          return {
            ...item,
            updated_at: new Date(item.updated_at),
            created_at: new Date(item.created_at),
          };
        });

        domains.value = mappedDomains;
      }
    } catch (error) {
    } finally {
      loading.value = false;
    }
  };

  //   FIND Domain
  const findDomain = async (id) => {
    try {
      const response = await api.get(`/admin/xpbx/settings/domain/${id}`);

      if (response?.data?.length) {
        domain.value = response.data[0];
      }
    } catch (error) {}
  };

  //   CREATE Domain
  const createDomain = async (data) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.post(`/admin/xpbx/settings/domain`, data);

      if (response?.data?.id) {
        domains.value = [response.data, ...domains.value];
        n("notify.xpbx.create_domain", "success");
      } else {
        n("notify.xpbx.error_create_domain");
      }
    } catch (error) {
      n("notify.xpbx.error_create_domain");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   UPDATE Domain
  const updateDomain = async (data, id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.put(`/admin/xpbx/settings/domain/${id}`, data);

      if (response?.data?.id) {
        const index = domains.value.findIndex((i) => i.id === id);
        domains.value[index] = response.data;
        n("notify.xpbx.edit_domain", "success");
      } else {
        n("notify.xpbx.error_edit_domain");
      }
    } catch (error) {
      n("notify.xpbx.error_edit_domain");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   DELETE Domain
  const deleteDomain = async (id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.delete(`/admin/xpbx/settings/domain/${id}`);

      if (response.status === 200) {
        n("notify.xpbx.delete_domain", "success");

        domains.value = domains.value.filter((i) => i.id !== id);
      } else {
        n("notify.xpbx.error_delete_domain");
      }
      return response;
    } catch (error) {
      n("notify.xpbx.error_delete_domain");
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  return {
    domain,
    loading,
    domains,
    findDomains,
    findDomain,
    createDomain,
    updateDomain,
    deleteDomain,
  };
}
